import { BiError } from "react-icons/bi";
import ModalImagePreview from "./Reports/ModalDetailPrint/ModalImagePreview";
import { Skeleton } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Reports, { ChecklistReportType } from "../service/Reports";
import './stylesImg.css'
import { trackEventMatomo } from "../helpers/matomo";

const ImagesCmp = ({ images, selected }: { images: string[], selected: ChecklistReportType }) => {

  const reportsService = useMemo(() => new Reports(), []);
  const [returnedImg, setReturnedImg] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string>('');
  const [open, setOpen] = useState(false);

  const getImages = useCallback(async () => {
    setLoading(true)
    const response = await reportsService.getImages(images || [])
    if (response) {
      setReturnedImg(response)
      setLoading(false)
    }
  }, [images, reportsService, selected?.itens])

  useEffect(() => {
    getImages()
  }, [getImages])

  return (loading) ? (<Skeleton variant="rounded" width={150} height={200} />) : (
    <>
      {open &&
        <ModalImagePreview
          image={image}
          open={open}
          setOpen={setOpen}
        />}
      <div className="divPrincipal">
        {returnedImg?.map((e: string, i: number) => (
          e.length > 0 ?
            <div key={`imagem${i}`} className="div2">
              <div
                className='divImg'
                key={`${e}${i}`}>
                <img onClick={() => {
                  trackEventMatomo('componente de imagem', 'click', 'button', 'abre imagem')
                  setOpen(true)
                  setImage(e)
                }}
                  className="img"
                  src={e}
                  alt={`Subitem ${i}`}
                  key={i} />
              </div>
            </div>
            : (
              <div className="div2">
                <div className="div3">
                  <div className='imgNotFound'>
                    <BiError size={18} />
                    <span style={{ fontSize: '10px', textAlign: 'center' }}>{'imagem nao encontrada'}</span>
                  </div>
                </div>
              </div>
            )
        ))}
      </div >
    </>
  )
}

export default ImagesCmp;