import { SettingsDiv } from './Style'
import { Label } from '../Notifications/style/SingleNotificationStyle'
import { useTranslation } from 'react-i18next';
import { campoOcultosType } from './ModalDetailPrint/ModalDetailPrint';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { trackEventMatomo } from '../../helpers/matomo';

type props = {
  camposOcultos: campoOcultosType
  showNotSelected: boolean,
  setShowNotSelected: Function,
  showScoring: boolean,
  setShowScoring: Function,
  showHideFields: boolean,
  setShowHideFields: Function,
  showHeaders: boolean,
  setShowHeaders: Function,
  hideHierarchy: {
    cliente: boolean,
    local: boolean,
    conjunto: boolean,
    unidade: boolean
  },
  setHideHierarchy: Function
  hideEquipe: boolean
  setHideEquipe: Function
  hideUser: boolean
  setHideUser: Function
  hideIdent: boolean
  setHideIdent: Function
  hideAprov: boolean
  setHideAprov: Function
  hideDate: { inicio: boolean, fim: boolean }
  setHideDate: Function,
  hideNAplica: boolean,
  setHideNAplica: Function
}

export default function SettingsDivComp({
  camposOcultos,
  showNotSelected,
  setShowNotSelected,
  showScoring,
  setShowScoring,
  showHideFields,
  setShowHideFields,
  showHeaders,
  setShowHeaders,
  hideHierarchy,
  setHideHierarchy,
  hideEquipe,
  setHideEquipe,
  hideUser,
  setHideUser,
  hideIdent,
  setHideIdent,
  hideAprov,
  setHideAprov,
  hideDate,
  setHideDate,
  hideNAplica,
  setHideNAplica
}: props) {
  const { t } = useTranslation('translation');
  const { hierarchyLabel } = useContext(UserContext);
  return (
    <SettingsDiv>
      <div className='columns'>
        {window.location.pathname !== '/preenchimento'
          && <>
            <div className='column'>
              <Label>Hierarquia</Label>
              <Label className='checkbox'>
                <input
                  type="checkbox"
                  className='checkbox'
                  checked={!hideHierarchy.cliente}
                  onClick={() => {
                    trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                    setHideHierarchy({ ...hideHierarchy, cliente: !hideHierarchy.cliente })
                  }}
                />
                <p>Ocultar {hierarchyLabel.cliente}</p>
              </Label>
              <Label className='checkbox'>
                <input
                  type="checkbox"
                  className='checkbox'
                  checked={!hideHierarchy.local}
                  onClick={() => {
                    trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                    setHideHierarchy({ ...hideHierarchy, local: !hideHierarchy.local })
                  }}
                />
                <p>Ocultar {hierarchyLabel.local}</p>
              </Label>
              <Label className='checkbox'>
                <input
                  type="checkbox"
                  className='checkbox'
                  checked={!hideHierarchy.conjunto}
                  onClick={() => {
                    trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                    setHideHierarchy({ ...hideHierarchy, conjunto: !hideHierarchy.conjunto })
                  }}
                />
                <p>Ocultar {hierarchyLabel.conjunto}</p>
              </Label>
              <Label className='checkbox'>
                <input
                  type="checkbox"
                  className='checkbox'
                  checked={!hideHierarchy.unidade}
                  onClick={() => {
                    trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                    setHideHierarchy({ ...hideHierarchy, unidade: !hideHierarchy.unidade })
                  }}
                />
                <p>Ocultar {hierarchyLabel.unidade}</p>
              </Label>
            </div>
            <div className='column'>
              <Label className='checkbox'>
                <input
                  type="checkbox"
                  className='checkbox'
                  checked={!hideEquipe}
                  onClick={() => {
                    trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                    setHideEquipe(!hideEquipe)
                  }}
                />
                <p>Ocultar {t('team')}</p>
              </Label>
              <Label className='checkbox'>
                <input
                  type="checkbox"
                  className='checkbox'
                  checked={!hideUser}
                  onClick={() => {
                    trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                    setHideUser(!hideUser)
                  }}
                />
                <p>Ocultar usuário  </p>
              </Label>
              <Label className='checkbox'>
                <input
                  type="checkbox"
                  className='checkbox'
                  checked={!hideIdent}
                  onClick={() => {
                    trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                    setHideIdent(!hideIdent)
                  }}
                />
                <p>Ocultar identificador</p>
              </Label>
              <Label className='checkbox'>
                <input
                  type="checkbox"
                  className='checkbox'
                  checked={!hideAprov}
                  onClick={() => {
                    trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                    setHideAprov(!hideAprov)
                  }}
                />
                <p>Ocultar aprovação</p>
              </Label>
              <Label className='checkbox'>
                <input
                  type="checkbox"
                  className='checkbox'
                  checked={!showScoring}
                  onClick={() => {
                    trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                    setShowScoring(!showScoring)
                  }}
                />
                <p>{t('checklists.printConfig.Ocultar_informacoes')}</p>
              </Label>
            </div>
          </>
        }
        <div className='column'>
          <Label>Datas</Label>
          <Label className='checkbox'>
            <input
              type="checkbox"
              className='checkbox'
              checked={!hideDate.inicio}
              onClick={() => {
                trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                setHideDate({ ...hideDate, inicio: !hideDate.inicio })
              }}
            />
            <p>Ocultar inicio</p>
          </Label>
          <Label className='checkbox'>
            <input
              type="checkbox"
              className='checkbox'
              checked={!hideDate.fim}
              onClick={() => {
                trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                setHideDate({ ...hideDate, fim: !hideDate.fim })
              }}
            />
            <p>Ocultar fim</p>
          </Label>
        </div>
      </div>
      {window.location.pathname !== '/preenchimento' && <>
        <hr />
        <Label className='checkbox'>
          <input
            type="checkbox"
            className='checkbox'
            checked={!showHeaders}
            onClick={() => {
              trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
              setShowHeaders(!showHeaders)
            }}
          />
          <p>{t('checklists.printConfig.Ocultar_cabecalho')}</p>
        </Label>
        <Label className='checkbox'>
          <input
            type="checkbox"
            className='checkbox'
            checked={!showNotSelected}
            onClick={() => {
              trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
              setShowNotSelected(!showNotSelected)
            }}
          />
          <p>{t('checklists.printConfig.Ocultar_alternativas')}</p>
        </Label>
        <Label className='checkbox'>
          <input
            type="checkbox"
            className='checkbox'
            checked={!hideNAplica}
            onClick={() => setHideNAplica(!hideNAplica)}
          />
          <p>Ocultar respostas não se aplica</p>
        </Label>
        {camposOcultos &&
          <Label className='checkbox'>
            <input
              type="checkbox"
              className='checkbox'
              checked={showHideFields}
              onClick={() => {
                trackEventMatomo('Relatórios', 'click', 'checkbox', 'seleciona valor')
                setShowHideFields(!showHideFields)
              }}
            />
            <p>Exibir campos ocultos</p>
          </Label>}
      </>}
    </SettingsDiv>
  )
}
