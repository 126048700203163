import { ChecklistSubitem, ItemChecklist, ocorrenciaType } from "../../../service/Checklists";
import { ChecklistReportType, ChecklistSubitemReport } from "../../../service/Reports";
import RenderSwitch from "./functions/RenderSwitch";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import TicketListReportComponent from "../TicketListReportComponent";
import { Typography } from "@material-ui/core";
import TicketsListComponent from "../TicketsListComponent";
import Grid from '@mui/material/Unstable_Grid2';
import '../styles.css';

export default function ModalDetailContent({
  itemIndex,
  showScoring,
  item,
  selected,
  showNotSelected,
  hideNAplica,
}: {
  itemIndex: number,
  showScoring?: boolean,
  item: ItemChecklist | ocorrenciaType,
  selected: ChecklistReportType,
  showNotSelected?: boolean,
  hideNAplica: boolean,
}) {

  const verificaConformidade = (subItem: ChecklistSubitem) => {
    if (typeof subItem.threshold !== 'number') { subItem.threshold = -1 }
    let valido = true;
    if (typeof subItem.nota === 'number' && typeof subItem.threshold === 'number') {
      if (subItem.nota <= subItem.threshold) {
        valido = false;
      }
    }
    return valido;
  };

  const tipoExibido = (value: string) => {
    switch (value) {
      case 'avaliacao':
        return '(Avaliação)'
      case 'multiple':
        return '(Multiplo)'
      case 'unique':
        return '(Unico)'
      case 'textoCurto':
        return '(Texto curto)'
      case 'textoLongo':
        return '(Texto longo)'
      case 'list':
        return '(Lista de textos)'
      case 'email':
        return '(E-mail)'
      case 'placa':
        return '(Placa)'
      case 'cpfcnpj':
        return '(CPF / CNPJ)'
      case 'cep':
        return '(CEP)'
      case 'assinatura':
        return '(Assinatura)'
      case 'rg':
        return '(RG)'
      case 'data':
        return '(Data)'
      case 'hora':
        return '(Hora)'
      case 'telefone':
        return '(Telefone)'
      case 'numero':
        return '(Numero)'
      default:
        return (<div>AAAAAAA</div>)
    }
  }

  function checkNAplica(subitem: ChecklistSubitem) {
    if (subitem.tipo === 'avaliacao') {
      if (typeof subitem.nota === 'number') {
        const nAplica = subitem.avaliacao?.find((ele, ind) => ind === subitem.nota)
        if (nAplica.valor === -1 && !hideNAplica)
          return true
      }
    }
    return false
  }

  return (
    <Grid container key={itemIndex} spacing={1}>
      <Grid xs={10} key={itemIndex} style={{
        fontFamily: 'Arial',
        fontSize: '1.4em'
      }}>{
          showScoring ?
            <>
              <Typography
                className="typography"
                variant="h6"
              >
                Item - {item.nome}
              </Typography>
              <span style={{ fontWeight: '100' }}>
                &nbsp;- Scoring: {selected?.itens[itemIndex].scorePorcentagem}
              </span>
              <Typography
                className="typography"
                variant='subtitle2'
              >
                subitens:
              </Typography>
            </> :
            <>
              <Typography
                variant="h6"
                className="typography"
              >
                Item - {item.nome}
              </Typography>
              <Typography
                variant='subtitle2'
                className="typography"
              >
                subitens:
              </Typography>
            </>
        }
      </Grid>
      <>
        {
          item.subitens?.map((ele, subIndex) => {
            return !ele.checked ? <></> : (
              <Grid xs={10}>
                {!checkNAplica(ele as ChecklistSubitem) && <>
                  <Typography style={{ color: 'gray', fontWeight: 'bold' }} variant='subtitle2'>
                    <div style={{wordBreak:'break-word'}}>
                      <span style={{ fontWeight: 500 }}>
                        ({subIndex + 1})
                      </span>&nbsp;
                      - {ele.nome}
                      {
                        ele.tipo === 'avaliacao' && verificaConformidade(ele as ChecklistSubitem) ?
                          (
                            <div className="icon-text">
                              <span className="icon has-text-success">
                                <IoMdCheckmarkCircle className='is-success' />
                              </span>
                            </div>
                          ) : ele.tipo === 'avaliacao' && !verificaConformidade(ele as ChecklistSubitem) && (
                            <div className="icon-text">
                              <span className="icon has-text-success">
                                <MdCancel className='icon has-text-danger is-small' />
                              </span>
                            </div>)}
                    </div>
                    <p style={{ color: 'gray', fontWeight: '300' }}>{tipoExibido(ele.tipo)}</p>
                  </Typography>
                  {RenderSwitch(ele as ChecklistSubitemReport, showNotSelected as boolean, selected)}
                </>}
                {
                  showScoring &&
                  <span style={{ color: 'gray', fontWeight: 'bold' }}>Scoring: {selected?.itens[itemIndex]?.subitens[subIndex]?.scorePorcentagem}</span>}
                {ele?.chamadosConfig && (
                  <>
                    {ele?.chamadosConfig &&
                      <TicketListReportComponent
                        addingTickets={{
                          itemIndex,
                          subItemIndex: subIndex,
                          active: true
                        }}
                        chamadosObj={ele?.chamados}
                        chamadosConfig={ele?.chamadosConfig}
                        formId={selected.idForm ? selected.idForm : selected.id || '-'}
                        inicio={Number(selected.inicio)}
                        commingFromSub={true}
                        chamadosConfigSub={ele.chamadosConfig}
                        selected={selected}
                      />}
                  </>
                )}
                {
                  !ele.chamadosConfig &&
                  ele.chamados &&
                  ele.chamados.length &&
                  <TicketsListComponent
                    chamadosObj={ele.chamados}
                    chamados={ele.chamados}
                    chamadosConfig={undefined}
                  />
                }
              </Grid>
            )
          })}
      </>
    </Grid>
  )
}
