import { Accordion, AccordionSummary, Divider, TextField } from '@material-ui/core'
import { useContext, useRef, useState } from 'react'
import { FiltersContainer, Label } from './style/SingleNotificationStyle'
import { UserContext } from '../../context/UserContext'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import './style.css';
import { useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import { Autocomplete } from '@mui/material';
import { IoFilter } from "react-icons/io5";
import { RxReset } from "react-icons/rx";
import { TicketsContext } from '../../context/TickestsContext';
import '@mui/x-date-pickers/themeAugmentation'
import GetFilters from '../../service/GetFilters';
import AutocompleteWithApi from '../Autocomplete/AutocompleteWithApi';
import DatePickerFim from '../DatePicker/DatePickerFim';
import DatePickerInicio from '../DatePicker/DatePickerInicio';
import TagComp from '../TagComp/TagComp';
import { trackEventMatomo } from '../../helpers/matomo';

type props = {
  refetch: Function,
  isFetching: boolean,
  isRefetching: boolean,
  loadingData: boolean
}

const ticketService = new GetFilters();

export default function TicketsFilters({
  refetch,
}: props) {

  const { t } = useTranslation('translation');
  const { userData, hierarchyLabel } = useContext(UserContext)
  const {
    emitidos,
    setEmitidos,
    ordem,
    setOrdem,
    ordenarPor,
    setOrdenarPor,
    userEmissor,
    setUserEmissor,
    modelosNovos,
    setModelosNovos,
    modelos,
    setModelos,
    inicioRangeDe,
    setInicioRangeDe,
    inicioRangeAte,
    setInicioRangeAte,
    previsaoRangeDe,
    setPrevisaoRangeDe,
    previsaoRangeAte,
    setPrevisaoRangeAte,
    clientes,
    setClientes,
    locais,
    setLocais,
    conjuntos,
    setConjuntos,
    unidades,
    setUnidades,
    expirado,
    setExpirado,
    status,
    setStatus,
    setDefaultValues,
    responsavel,
    setResponsavel
  } = useContext(TicketsContext)

  const [openUser, setOpenUser] = useState<boolean>(false)
  const [openCliente, setOpenCliente] = useState<boolean>(false)
  const [openLocal, setOpenLocal] = useState<boolean>(false)
  const [openConjunto, setOpenConjunto] = useState<boolean>(false)
  const [openUnidade, setOpenUnidade] = useState<boolean>(false)
  const [openResponsavel, setOpenResponsavel] = useState<boolean>(false)
  const [openChamadoLegado, setOpenChamadoLegado] = useState<boolean>(false);
  const [openChamado, setOpenChamado] = useState<boolean>(false);

  const statusArray = [
    { concluido: 'Concluído' },
    { cancelado: 'Cancelado' },
    { emandamento: 'Em Andamento' },
    { pendente: 'Pendente' },
    { recusado: 'Recusado' }
  ]

  const orderArray = [
    { inicio: 'Por data de cadastro' },
    { usuarioNome: 'Por usuário' },
    { nome: 'Por chamado' },
    { responsavel: 'Por responsável' },
    { prioridade: 'Por prioridade' },
    { novo: 'Por modelo de chamado' },
    { dataPrevisao: 'Por data limite ' },
    { status: 'Por estado' }
  ]

  const { data: usersResponse, isLoading: isLoadingUsers, refetch: getUsers } = useQuery({
    queryKey: ['GET_USERS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await ticketService.getUsuarios(token, true)
        return response.users
      }
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnMount: false,
  })

  const { data: clientesResponse, isLoading: isLoadingClientes, refetch: getClientes } = useQuery({
    queryKey: ['GET_CLIENTES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await ticketService.getClientes(token)
        return response.clientes
      }
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnMount: false,
  })

  const { data: locaisResponse, isLoading: isLoadingLocais, refetch: getLocais } = useQuery({
    queryKey: ['GET_LOCAIS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await ticketService.getLocais(token)
        return response.locais
      }
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnMount: false,
  })

  const { data: conjuntoResponse, isLoading: isLoadingConjunto, refetch: getConjuntos } = useQuery({
    queryKey: ['GET_CONJUNTO'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await ticketService.getConjuntos(token)
        return response.conjuntos
      }
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnMount: false,
  })

  const { data: chamadosResponse, isLoading: isLoadingChamados, refetch: getChamados } = useQuery({
    queryKey: ['GET_CHAMADOS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await ticketService.getChamados(token, false)
        return response.chamadosNovos
      }
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnMount: false,
  })

  const { data: chamadosLegadoResponse, isLoading: isLoadingChamadosLegado, refetch: getChamadosLegado } = useQuery({
    queryKey: ['GET_CHAMADOSLEGADO'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await ticketService.getChamados(token, true)
        return response.chamadosLegado
      }
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnMount: false,
  })

  const { data: unidadeResponse, isLoading: isLoadingUnidade, refetch: getUnidades } = useQuery({
    queryKey: ['GET_UNIDADES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await ticketService.getUnidades(token)
        return response.unidades
      }
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnMount: false,
  })

  const ordemCheck = (value: string, sortValue: boolean) => {
    switch (value) {
      case 'inicio': case 'dataPrevisao':
        return (sortValue ? 'Ordem de data crescente' : 'Ordem de data decrescente')
      case 'usuarioNome': case 'nome': case 'responsavel': case 'prioridade': case 'novo': case 'status':
        return (sortValue ? 'Ordem alfabética crescente (A-Z)' : 'Ordem alfabética  decrescente (Z-A)')
    }
  }

  const selectRef = useRef<HTMLSelectElement>(null)



  return (
    <Accordion className='acordion'>
      <AccordionSummary className='acordionSummary' expandIcon={<ExpandMoreIcon />} >
        <span style={{ width: '100%', textAlign: 'center' }}>Filtros</span>
      </AccordionSummary>
      <FiltersContainer key={Math.random()}>
        <div className='columns'>
          <span className="checkbox column">
            <Label className="checkbox">
              <input
                onClick={() => trackEventMatomo('filtro chamados', 'click', 'checkbox', 'edita valor')}
                style={{ marginBottom: '10px' }}
                checked={emitidos}
                onChange={(e) => {
                  setEmitidos(e.target.checked)
                }}
                type="checkbox"
              />&nbsp;
              Mostrar chamados emitidos por&nbsp; <b>{userData.userName}</b>
            </Label>
          </span>
          <button
            type='submit'
            onClick={() => {
              trackEventMatomo('filtro chamados', 'click', 'button', 'pesquisar')
              setEmitidos(false);
              setDefaultValues()
              localStorage.removeItem('filtros')
              refetch()

            }}
            style={{ marginRight: '5px' }}
            className='button is-danger is-small'>
            Limpar filtros&nbsp; <RxReset />
          </button>
          <button
            type='submit'
            className="button is-success is-small"
            onClick={() => {
              trackEventMatomo('filtro chamados', 'click', 'button', 'limpa filtros')
              const filter = {
                emitidos: emitidos,
                ordenarPor: ordenarPor,
                ordem: ordem,
                userEmissor: userEmissor,
                modelosNovos: modelosNovos,
                modelos: modelos,
                inicioRange: {
                  de: new Date(inicioRangeDe).setHours(0, 0, 0, 0),
                  ate: inicioRangeAte
                },
                previsaoRange: {
                  de: new Date(previsaoRangeDe).setHours(0, 0, 0, 0),
                  ate: previsaoRangeAte
                },
                clientes: clientes,
                locais: locais,
                conjuntos: conjuntos,
                unidades: unidades,
                expirado: expirado,
                status: status,
              };
              localStorage.setItem('filtros', JSON.stringify(filter))
            }}
          >
            {t('filter')}&nbsp;<IoFilter />
          </button>
        </div>
        <Label className='filtersTitle'>Ordenação</Label>
        <Divider />
        <div className='columns' style={{ width: '100%' }}>
          <Autocomplete
            disableClearable
            defaultValue={ordenarPor || undefined}
            getOptionLabel={option => option.label}
            getOptionKey={option => option.value}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            className='column'
            size="small"
            style={{ fontSize: '10px !important' }}
            filterSelectedOptions
            onClick={() => trackEventMatomo('filtro chamados', 'click', 'select', 'seleciona valor')}
            options={orderArray
              ?.map((ele: any) => ({
                label: String(Object.values(ele)),
                value: String(Object.keys(ele)),
              }))}
            onChange={(event: any, newValue: { label: string, value: string } | null) => {
              setOrdenarPor(newValue)
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.value}>
                <span>{option.label}</span>
              </li>)}
            renderInput={(params) => <TextField {...params} label={'Ordenar por'} />}
          />
          <div className='column'>
            <Label>Ordem:</Label>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>
                <input
                  onClick={() => trackEventMatomo('filtro chamados', 'click', 'radio', 'seleciona valor')}
                  onChange={() => setOrdem(true)}
                  defaultChecked={ordem || false}
                  type="radio"
                  name="status" />&nbsp;
                {ordemCheck(ordenarPor?.value as string, true)}
              </label>
              <label>
                <input
                  onClick={() => trackEventMatomo('filtro chamados', 'click', 'radio', 'seleciona valor')}
                  onChange={() => setOrdem(false)}
                  defaultChecked={ordem === false}
                  type="radio"
                  name="status" />&nbsp;
                {ordemCheck(ordenarPor?.value as string, false)}
              </label>
            </div>
          </div>
        </div>
        <Label className='filtersTitle'>Por datas</Label>
        <div className='columns' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='column' style={{ display: 'flex', flexDirection: 'column', maxWidth: 200 }}>
            <Label>Data de cadastro:</Label>
            <DatePickerInicio maxDate={inicioRangeAte} inicio={inicioRangeDe} setInicio={setInicioRangeDe} />
            <DatePickerFim inicio={inicioRangeDe} fim={inicioRangeAte} setFim={setInicioRangeAte} />
          </div>
          <div className='column' style={{ display: 'flex', flexDirection: 'column', maxWidth: 200 }}>
            <Label>Data limite:</Label>
            <DatePickerInicio maxDate={previsaoRangeAte} inicio={previsaoRangeDe} setInicio={setPrevisaoRangeDe} />
            <DatePickerFim inicio={previsaoRangeDe} fim={previsaoRangeAte} setFim={setPrevisaoRangeAte} />
          </div>
        </div>
        <Label className='filtersTitle'>Por Usuários/Responsáveis</Label>
        <Divider />
        <AutocompleteWithApi
          open={openUser}
          value={userEmissor}
          setOpen={setOpenUser}
          response={usersResponse}
          loading={isLoadingUsers}
          setValue={setUserEmissor}
          textoVazio={'Sem usuários correspondentes'}
          callResponse={getUsers}
          textoLoading={"Buscando usuários..."}
          textLabel={`${t('Reports.multipleUserSelect')}`}
        />
        <AutocompleteWithApi
          open={openResponsavel}
          value={responsavel}
          setOpen={setOpenResponsavel}
          response={usersResponse}
          loading={isLoadingUsers}
          setValue={setResponsavel}
          textoVazio={'Sem responsaveis correspondentes'}
          callResponse={getUsers}
          textoLoading={"Buscando responsáveis..."}
          textLabel={`Selecione um ou mais responsáveis`}
        />
        <Label className='filtersTitle'>Por chamados</Label>
        <Divider />
        {userData.migrado === true &&
          <AutocompleteWithApi
            open={openChamado}
            value={modelosNovos}
            setOpen={setOpenChamado}
            response={chamadosResponse}
            loading={isLoadingChamados}
            setValue={setModelosNovos}
            textoVazio={'Sem chamados correspondentes'}
            callResponse={getChamados}
            textoLoading={"Buscando chamados..."}
            textLabel={`Selecione um ou mais modelos de chamado 2.0`}
          />}
        <AutocompleteWithApi
          open={openChamadoLegado}
          value={modelos}
          setOpen={setOpenChamadoLegado}
          response={chamadosLegadoResponse}
          loading={isLoadingChamadosLegado}
          setValue={setModelos}
          textoVazio={'Sem chamados correspondentes'}
          callResponse={getChamadosLegado}
          textoLoading={"Buscando chamados..."}
          textLabel={`Selecione um ou mais modelos de chamado`}
        />
        <Label className='filtersTitle'>Por Hierarquia</Label>
        <Divider />
        <AutocompleteWithApi
          open={openCliente}
          value={clientes}
          setOpen={setOpenCliente}
          response={clientesResponse}
          loading={isLoadingClientes}
          setValue={setClientes}
          textoVazio={`Sem ${hierarchyLabel.cliente} correspondentes`}
          callResponse={getClientes}
          textoLoading={"Buscando clientes..."}
          textLabel={`Selecione um ${hierarchyLabel.cliente}`}
        />
        <AutocompleteWithApi
          open={openLocal}
          value={locais}
          setOpen={setOpenLocal}
          response={locaisResponse}
          loading={isLoadingLocais}
          setValue={setLocais}
          textoVazio={`Sem ${hierarchyLabel.local} correspondentes`}
          callResponse={getLocais}
          textoLoading={"Buscando locais..."}
          textLabel={`Selecione um ${hierarchyLabel.local}`}
        />
        <AutocompleteWithApi
          open={openConjunto}
          value={conjuntos}
          setOpen={setOpenConjunto}
          response={conjuntoResponse}
          loading={isLoadingConjunto}
          setValue={setConjuntos}
          textoVazio={`Sem ${hierarchyLabel.conjunto} correspondentes`}
          callResponse={getConjuntos}
          textoLoading={"Buscando conjuntos..."}
          textLabel={`Selecione um ${hierarchyLabel.conjunto}`}
        />
        <AutocompleteWithApi
          open={openUnidade}
          value={unidades}
          setOpen={setOpenUnidade}
          response={unidadeResponse}
          loading={isLoadingUnidade}
          setValue={setUnidades}
          textoVazio={`Sem ${hierarchyLabel.unidade} correspondentes`}
          callResponse={getUnidades}
          textoLoading={"Buscando conjuntos..."}
          textLabel={`Selecione um ${hierarchyLabel.unidade}`}
        />

        <Label className='filtersTitle'>Por status</Label>
        <Divider />
        <Label>Expiração</Label>
        <select
          defaultValue={typeof expirado !== 'boolean' ? 'null' : String(expirado)}
          onChange={(elem) => {
            setExpirado(elem.target.value === 'null' ? null : elem.target.value === 'true' ? true : false)
          }}
          className='select is-fullwidth'>
          <option value={'null'}>Mostrar Todos</option>
          <option value={`${true}`}>Mostrar apenas chamados expirados</option>
          <option value={`${false}`}>Mostrar apenas chamados dentro do prazo</option>
        </select>
        <Label>{t('status')}</Label>
        <select
          ref={selectRef}
          value={''}
          onChange={(elem) => {
            if (status?.length && status?.length === 4) {
              elem.target.value = 'null'
            }
            const val = JSON.parse(JSON.stringify(elem.target.value))
            setStatus((prev: any) => val === 'null' ? null : prev ? [...prev, val as string[]] : [val as string]);
            if (selectRef.current && val !== 'null') {
              selectRef.current.value = ''
            }
          }}
          className='select is-fullwidth'>
          <option disabled value={''}>{t('select')}...</option>
          <option value={'null'}>Todos</option>
          {statusArray?.map((elem, index) =>
            <option
              disabled={status?.includes(Object.keys(elem)[0])}
              key={index}
              value={Object.keys(elem)[0]}>
              {Object.values(elem)[0]}
            </option>)}
        </select>
        <div>
          <TagComp values={status} setValues={setStatus} valuesArray={statusArray as []} />
        </div>
      </FiltersContainer>
    </Accordion >
  )
}
