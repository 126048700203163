export const HTMLTERM = `<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="pt" lang="pt">

<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <title>9c42e624-5c90-4b19-b4fe-7f8213ad7497</title>
  <meta name="author" content="VIVIANE FERREIRA" />
  <style type="text/css">
    * {
      margin: 0;
      padding: 0;
      text-indent: 0;
    }

    h1 {
      color: #272727;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      text-decoration: none;
      font-size: 11pt;
    }

    .p,
    p {
      color: #272727;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
      margin: 0pt;
    }

    .s1 {
      color: black;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
    }

    .s2 {
      color: black;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      text-decoration: none;
      font-size: 9pt;
    }

    h3 {
      color: #272727;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      text-decoration: none;
      font-size: 9pt;
    }

    .s3 {
      color: #272727;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
    }

    .a {
      color: #00F;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: underline;
      font-size: 9pt;
    }

    .s4 {
      color: #00F;
      font-family: Calibri, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: underline;
      font-size: 11pt;
    }

    h2 {
      color: #272727;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      text-decoration: none;
      font-size: 10pt;
    }

    .s6 {
      color: black;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
    }

    li {
      display: block;
    }

    #l1 {
      padding-left: 0pt;
      counter-reset: c1 1;
    }

    #l1>li>*:first-child:before {
      counter-increment: c1;
      content: "(" counter(c1, lower-latin)") ";
      color: #272727;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
    }

    #l1>li:first-child>*:first-child:before {
      counter-increment: c1 0;
    }

    li {
      display: block;
    }

    #l2 {
      padding-left: 0pt;
      counter-reset: d1 1;
    }

    #l2>li>*:first-child:before {
      counter-increment: d1;
      content: counter(d1, decimal)" ";
      color: #272727;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
    }

    #l2>li:first-child>*:first-child:before {
      counter-increment: d1 0;
    }

    li {
      display: block;
    }

    #l3 {
      padding-left: 0pt;
      counter-reset: e1 1;
    }

    #l3>li>*:first-child:before {
      counter-increment: e1;
      content: counter(e1, decimal)" ";
      color: black;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
    }

    #l3>li:first-child>*:first-child:before {
      counter-increment: e1 0;
    }

    li {
      display: block;
    }

    #l4 {
      padding-left: 0pt;
      counter-reset: f1 1;
    }

    #l4>li>*:first-child:before {
      counter-increment: f1;
      content: counter(f1, decimal)" ";
      color: black;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
    }

    #l4>li:first-child>*:first-child:before {
      counter-increment: f1 0;
    }

    li {
      display: block;
    }

    #l5 {
      padding-left: 0pt;
      counter-reset: g1 1;
    }

    #l5>li>*:first-child:before {
      counter-increment: g1;
      content: "(" counter(g1, lower-latin)") ";
      color: black;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
    }

    #l5>li:first-child>*:first-child:before {
      counter-increment: g1 0;
    }

    li {
      display: block;
    }

    #l6 {
      padding-left: 0pt;
    }

    #l6>li>*:first-child:before {
      content: "– ";
      color: black;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
    }

    #l7 {
      padding-left: 0pt;
      counter-reset: h2 1;
    }

    #l7>li>*:first-child:before {
      counter-increment: h2;
      content: counter(h2, upper-roman)" ";
      color: black;
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 9pt;
    }

    #l7>li:first-child>*:first-child:before {
      counter-increment: h2 0;
    }
  </style>
</head>

<body>
  <h1 style="padding-top: 4pt;text-indent: 0pt;text-align: center;">TERMOS DE USO E POLÍTICA DE PRIVACIDADE DE DADOS
  </h1>
  <p style="padding-top: 7pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">A Axyma faz parte da Synoro Negócios Inovadores,
    grupo com mais de 15 anos de atuação nos segmentos de tecnologia da informação, aplicativos móveis, consultoria e
    gestão financeira.</p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Está incorporada no mesmo ambiente das demais
    empresas do grupo, o que promove sinergia entre as especialidades, multidisciplinaridade e otimização do uso de
    recursos. Essas características nos fazem competitivos, ágeis e continuamente atualizados com os mais avançados
    métodos e tecnologias.</p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p class="s1" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Apoiamos nossos clientes na <b>melhoria
      de processos </b>de negócios, tanto pelo uso racional e efetivo de <b>ferramentas tecnológicas </b>quanto pela
    <b>capacidade analítica </b>aportada à operação, trazendo ganhos em <b>planejamento, qualidade, controle e
      produtividade.</b></p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <h3 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">COMO USAR NOSSOS SERVIÇOS</h3>
  <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">O <b>Axyma Forms </b>é uma
    aplicação para empresas que utilizam checklists e ordens de serviços para a realização de auditorias ou inspeções em
    processos que são rotineiros em diversos segmentos de mercado.</p>
  <h3 style="padding-top: 10pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">O uso de nossos serviços não lhe
    dá direitos de propriedade intelectual. <span class="p">Estes termos não conferem a você o direito de usar quaisquer
      marcas ou logotipos utilizados da nossa aplicação. Não remova, oculte ou altere quaisquer avisos legais exibidos
      na nossa Aplicação.</span></h3>
  <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a
      href="mailto:contato@axyma.com.br" class="s3" target="_blank">Enquanto usuário de nossos serviços, podemos
      enviar-lhe anúncios de funcionalidades e condições de atendimento, mensagens administrativas e outras informações
      de natureza institucional ou técnica. Respeitamos sua privacidade e, a qualquer momento, você poderá desativar
      algumas dessas comunicações entrando em contato com nosso time contato@axyma.com.br</a></p>
  <h3 style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">SUA CONTA</h3>
  <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Você precisará criar uma conta
    para utilizar nossa aplicação, conforme discriminado abaixo.</p>
  <h3 style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Para proteger sua conta, você,
    como usuário, deve manter a senha em sigilo<span class="p">. A atividade realizada na conta ou por seu intermédio é
      de sua responsabilidade enquanto usuário. Não recomendamos que a senha da conta seja reutilizada em outros
      aplicativos. </span>Caso tome conhecimento de uso não autorizado da sua senha ou conta, altere sua senha<span
      class="p">.</span></h3>
  <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Como usuário, você concorda em
    usar a aplicação apenas conforme previsto nestes Termos de Uso. Concorda, também, em não usar o software de qualquer
    forma que esteja em conflito com a legislação. <b>A título de exemplo, e não limitação, você concorda em NÃO</b>:
  </p>
  <p style="padding-top: 8pt;text-indent: 0pt;text-align: left;"><br /></p>
  <ol id="l1">
    <li data-list-text="(a)">
      <p style="padding-left: 18pt;text-indent: -13pt;text-align: left;">infringir as leis, os direitos de terceiros ou
        as nossas políticas internas;</p>
    </li>
    <li data-list-text="(b)">
      <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">postar conteúdo falso, impreciso,
        enganoso, difamatório ou ofensivo contra o software ou qualquer usuário;</p>
    </li>
    <li data-list-text="(c)">
      <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">distribuir ou postar spam não
        solicitado, ou comunicações eletrônicas em massa, correntes ou esquemas de pirâmide;</p>
    </li>
    <li data-list-text="(d)">
      <p style="padding-top: 8pt;padding-left: 18pt;text-indent: -13pt;text-align: left;">distribuir vírus ou quaisquer
        outras tecnologias que podem prejudicar a aplicação;</p>
    </li>
    <li data-list-text="(e)">
      <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">tentar modificar, traduzir,
        adaptar, modificar, descompilar, desmontar ou fazer engenharia reversa de qualquer programa de software da
        Aplicação;</p>
    </li>
    <li data-list-text="(f)">
      <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">copiar, modificar ou distribuir
        direitos ou conteúdo da Aplicação e/ou direitos autorais e marcas comerciais da empresa;</p>
    </li>
    <li data-list-text="(g)">
      <p style="padding-top: 8pt;padding-left: 18pt;text-indent: -13pt;text-align: left;">colher e/ou compartilhar
        quaisquer informações sobre outros Usuários sem o consentimento do mesmo.</p>
    </li>
  </ol>
  <h3 style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">CADASTRO</h3>
  <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a
      href="mailto:contato@axyma.com.br" class="s3" target="_blank">Para usufruir dos serviços da Axyma Forms a empresa
      precisará realizar um cadastro na aplicação. Os serviços disponibilizados pela Axyma Forms seguem regras de
      licenciamento que poderão variar entre modalidades, de acordo com o plano escolhido pela Empresa, para obter
      informações sobre os planos disponíveis e suas funcionalidades e valores, fale conosco através dos (11) 95969-4445
      e (11) 4305-8264 </a><a href="mailto:contato@axyma.com.br" class="a" target="_blank">contato@axyma.com.br</a><a
      href="mailto:contato@axyma.com.br" class="s3" target="_blank">.</a></p>
  <p style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">A empresa terá seu acesso a
    Aplicação suspenso, caso ocorra falta de pagamento e até que haja a regularização da situação, sem que isso implique
    qualquer indenização por parte da <b>Axyma </b>por prejuízos gerados à esta.</p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Esclarecemos que, durante a execução de testes ou
    atualizações da aplicação, uma ou mais funcionalidades poderão ficar indisponíveis. Trabalhamos para que as
    interrupções do serviço sejam as mais breves possíveis. Contamos com sua compreensão.</p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Ao contratar nossos serviços, a empresa CONTRATANTE
    também pode criar contas de usuário autorizadas para que seus colaboradores façam o devido login e utilizem os
    serviços da <b>Axyma</b>., por meio das interfaces apropriadas. Todos os colaboradores cadastrados pela Empresa
    estão também obrigatoriamente vinculados aos presentes Termos de Uso e ao Aviso de Privacidade.</p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Ao se registrar, a empresa concorda e declara que:
  </p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <ol id="l2">
    <li data-list-text="1">
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">– Não registrará e/ou usará dos serviços da
        <b>Axyma</b>. para qualquer finalidade que não seja o descrito nestes Termos e no plano escolhido;</p>
      <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
    </li>
    <li data-list-text="2">
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">– Será responsável por garantir que o
        processamento de dados do usuário na <b>Axyma</b>. seja feito conforme a regulamentação e a lei necessárias,
        inclusive para a exclusão e exportação de dados;</p>
      <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
    </li>
    <li data-list-text="3">
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">– Assumirá total e exclusiva responsabilidade
        em atribuir contas individuais e devidamente autorizadas de usuários colaboradores, sendo a exclusiva
        responsável por quaisquer prejuízos causados pelos usuários por vazamento de dados, acesso e utilização indevida
        de informações, compartilhamento sem prévia autorização, inexatidão de informações etc;</p>
      <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
    </li>
    <li data-list-text="4">
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">– Não compartilhará quaisquer informações
        sigilosas obtidas por meio da aplicação, sob pena de aplicação de multa no montante a ser definido de acordo com
        os danos diretos sofridos pela <b>Axyma</b>., sem prejuízo das demais indenizações cobradas judicialmente;</p>
      <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
    </li>
    <li data-list-text="5">
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">– As informações fornecidas durante o processo
        de registro, discriminadas no Aviso de Privacidade, são verdadeiras e atuais.</p>
      <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
    </li>
    <li data-list-text="6">
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">– Será obrigado a revisar e atualizar
        periodicamente seus dados cadastrais para garantir que estejam sempre atualizados e corretos.</p>
    </li>
  </ol>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <h3 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">PROTEÇÃO À PRIVACIDADE, DADOS PESSOAIS E DIREITOS
    AUTORAIS</h3>
  <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">O Aviso de Privacidade da
    <b>Axyma</b>. explica o modo como tratamos seus dados pessoais e protegemos sua privacidade quando você usa nossos
    serviços. Ao utilizá-los, você concorda que a <b>Axyma</b>. poderá realizar o tratamento desses dados de acordo com
    nosso Aviso de Privacidade, para alcançar a prestação de serviços e/ou entrega do produto em sua integralidade ou da
    melhor forma possível.</p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Conforme diretrizes da legislação brasileira
    aplicável à proteção de dados pessoais, especialmente a Lei 13.709/2018 – Lei Geral de Proteção de Dados (LGPD), a
    <b>Axyma </b>utilizará meios razoáveis de segurança da informação que estiverem ao seu alcance para garantir um
    tratamento seguro e adequado dos dados pessoais.</p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">A <b>Axyma </b>implementará medidas técnicas e
    gerenciais, preventivas e responsivas, de segurança para mitigar os riscos relacionados à violação de dados, não
    podendo, contudo, ser responsabilizada por situações fora do seu alcance, tais como negligência do próprio usuário
    com os dados pessoais, em especial, com suas credenciais de acesso.</p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Ao fazer uso de dados pessoais via produtos e
    serviços da <b>Axyma</b>, o usuário se compromete em informar apenas dados verídicos e obtidos de forma lícita. Em
    caso de qualquer descumprimento ao disposto nestes Termos, o usuário responderá individualmente por eventuais
    tratamentos indevidos, isentando a <b>Axyma</b>, na condição de operadora (processadora) de responsabilidade, por
    qualquer tratamento de dados indevido ou sem base legal.</p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Estes Termos não transferem a propriedade ou
    controle dos dados do usuário, seus afiliados ou dos seus próprios clientes à <b>Axyma. </b>Os dados gerados,
    obtidos ou coletados a partir da prestação dos serviços ora contratados são de propriedade exclusiva do usuário.</p>
  <h3 style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Qualquer violação aos direitos de
    Propriedade Intelectual poderá culminar, além da incidência das responsabilidades legais, no bloqueio sumário e sem
    aviso prévio da empresa, ocasião em que nada será devido a esta, <span class="p">ficando os infratores sujeitos às
      sanções civis e criminais correspondentes, nos termos das Leis nº 9.279/96, nº 9.610/98 e nº 9.609/98.</span></h3>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">O licenciamento de uso do software não confere ao
    contratante o direito de uso destas marcas ou demais ativos de propriedade industrial sob pena de violação à Lei nº
    9.279/96</p>
  <p style="padding-top: 3pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">A <b>Axyma </b><a
      href="mailto:contato@axyma.com.br" class="s3" target="_blank">disponibiliza um canal de comunicação para sanar
      dúvidas ou prestar esclarecimentos, pelo e- mail: </a><a href="mailto:contato@axyma.com.br" class="a"
      target="_blank">contato@axyma</a><a href="mailto:contato@axyma.com.br" class="s4" target="_blank">.com.br</a></p>
  <p style="text-indent: 0pt;text-align: left;"><br /></p>
  <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">POLÍTICA DE PRIVACIDADE</h2>
  <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">A
    Axyma inscrita no CNPJ sob o nº 24.563.639/0001-43, comunica, por meio deste Aviso de Privacidade, que as
    informações sobre o tratamento de dados realizado estão em conformidade com as leis brasileiras aplicáveis à
    proteção de dados, especialmente a Lei nº 13.709/2018 – Lei Geral de Proteção de Dados (LGPD).</p>
  <p class="s1" style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">A
    Axyma está comprometida com os valores de ética e transparência. Essa cultura é conectada ao nosso DNA pois temos
    como missão manter sempre a privacidade e a segurança dos dados pessoais de nossos clientes, parceiros e
    colaboradores.</p>
  <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Sobre os dados pessoais
    tratados na Axyma :</p>
  <ol id="l3">
    <li data-list-text="1">
      <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">–
        Se você é cliente ou usuário da nossa aplicação, coletamos dados pessoais de cadastro e autenticação no sistema,
        como nome completo, e-mail e contato telefônico.</p>
    </li>
    <li data-list-text="2">
      <p class="s1" style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">–
        Se você se relaciona conosco através do chat online do site, coletamos informações para sanar dúvidas e prestar
        esclarecimentos, tais como nome completo, e-mail corporativo e contato telefônico.</p>
    </li>
    <li data-list-text="3">
      <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">–
        Para enviar newsletters com o objetivo de informar sobre atualizações do sistema e sobre a disponibilização de
        conteúdo de interesse do usuário, precisamos coletar seu nome completo e endereço de e-mail.</p>
    </li>
    <li data-list-text="4">
      <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">–
        Nós também coletamos informações de navegação no site para entender as preferências do usuário e aprimorar a sua
        experiência: endereço IP, localização geográfica, fonte de referência, tipo de navegador, duração da visita e
        páginas visitadas.</p>
    </li>
    <li data-list-text="5">
      <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">–
        Nós usamos software apropriado para medir a qualidade e a eficácia do nosso sistema, a fim de aprimorá-lo
        constantemente. Esse recurso pode coletar dados pessoais indiretos, como informações de uso, eventos que ocorrem
        dentro do aplicativo, uso agregado de dados, dados de desempenho e download, a localização da sua cidade, o
        modelo e a versão do dispositivo, o identificador de dispositivo (ou UDID), a versão do sistema operacional e
        suas credenciais de login no Axyma Forms.</p>
    </li>
    <li data-list-text="6">
      <p class="s1" style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">–
        Se você trabalha, trabalhou ou pretende trabalhar conosco, saiba que coletamos informações relacionadas a
        colaboradores para formalizar as admissões e gerenciamento do contrato de trabalho, bem como para atender leis
        trabalhistas, fiscais e previdenciárias, tais como nome completo, CPF, RG, CNH, CTPS, endereço, estado civil,
        naturalidade, telefone, e-mail, foto, dados bancários, informações profissionais e, eventualmente, informações
        de saúde.</p>
    </li>
  </ol>
  <h2 style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">SOBRE O USO DAS INFORMAÇÕES
    PESSOAIS</h2>
  <p class="s1" style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">A Axyma tratará apenas os
    dados pessoais necessários com os seguintes propósitos:</p>
  <ol id="l4">
    <li data-list-text="1">
      <p class="s1" style="padding-top: 8pt;padding-left: 12pt;text-indent: -7pt;text-align: left;">– Executar a
        prestação dos serviços e/ou entrega dos produtos da Axyma</p>
    </li>
    <li data-list-text="2">
      <p class="s1" style="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">– Estabelecer
        critérios de identificação do usuário cadastrado e suas atividades na plataforma ou no Website.</p>
    </li>
    <li data-list-text="3">
      <p class="s1" style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: left;">– Possibilitar a
        execução de funcionalidades na plataforma ou website, atendendo expectativas do usuário;</p>
    </li>
    <li data-list-text="4">
      <p class="s1" style="padding-left: 12pt;text-indent: -7pt;line-height: 10pt;text-align: left;">– Promover os
        serviços e produtos Axyma, disponibilizando conteúdo de interesse do usuário;</p>
    </li>
    <li data-list-text="5">
      <p class="s1" style="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">– Realizar a admissão
        de colaboradores, cumprindo com as obrigações contratuais e legais, além de conceder benefícios.</p>
    </li>
  </ol>
  <p class="s1" style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">Nos
    comprometemos em não utilizar as informações pessoais para quaisquer outras finalidades que não sejam as estipuladas
    neste Aviso.</p>
  <h2 style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">MEDIDAS DE SEGURANÇA DA INFORMAÇÃO
    APLICADAS</h2>
  <p class="s1" style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
    Adotamos controles gerenciais e técnicos para assegurar que as informações pessoais sejam acessadas apenas pelos
    colaboradores elegíveis a permissão de acesso. Todos os colaboradores são submetidos a compromisso de
    confidencialidade.</p>
  <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">Não
    nos responsabilizamos pela negligência do usuário no uso das próprias informações pessoais, ou de seus clientes, ou
    pela má-fé de terceiros, visando minimizar esse risco aplicamos as medidas de segurança adequadas</p>
  <h2 style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">COMPARTILHAMENTO DOS DADOS</h2>
  <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">As
    informações pessoais obtidas pela plataforma , são para uso interno, portanto, não se preocupe, pois os dados não
    serão compartilhados com terceiros, salvo nas seguintes hipóteses:</p>
  <p style="text-indent: 0pt;text-align: left;"><br /></p>
  <ol id="l5">
    <li data-list-text="(a)">
      <p class="s1" style="padding-left: 18pt;text-indent: -13pt;text-align: left;">sucessão empresarial;</p>
    </li>
    <li data-list-text="(b)">
      <p class="s1" style="padding-left: 18pt;text-indent: -13pt;text-align: left;">contratação de serviços de
        processamento de dados;</p>
    </li>
    <li data-list-text="(c)">
      <p class="s1" style="padding-left: 17pt;text-indent: -12pt;text-align: left;">sistemas de processamento (serviços
        de computação em nuvem);</p>
    </li>
    <li data-list-text="(d)">
      <p class="s1" style="padding-left: 18pt;text-indent: -13pt;text-align: left;">serviços de faturamento de créditos
        (financeiros);</p>
    </li>
    <li data-list-text="(e)">
      <p class="s1" style="padding-left: 18pt;text-indent: -13pt;text-align: left;">consultoria em tecnologia da
        informação e;</p>
    </li>
    <li data-list-text="(f)">
      <p class="s1" style="padding-left: 15pt;text-indent: -10pt;text-align: left;">serviços de assessoria jurídica.</p>
    </li>
  </ol>
  <p style="padding-top: 9pt;text-indent: 0pt;text-align: left;"><br /></p>
  <p class="s1" style="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">Somos obrigados por lei a
    compartilhar informações caso seja demandada por autoridades judiciais, policiais ou administrativas.</p>
  <h2 style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">TEMPO DE ARMAZENAMENTO DOS DADOS
  </h2>
  <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">A
    Axyma manterá armazenados os dados de acesso à Internet pelo prazo máximo de 12 meses, conforme estabelece o Marco
    Civil da Internet. Após esse período, os dados serão completamente excluídos, salvo se forem necessários para
    eventual exercício de direito em processo judicial ou administrativo.</p>
  <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
    Asseguramos que serão eliminados todos os dados pessoais no caso de encerramento da relação contratual ou por
    oposição ao tratamento de dados por parte do titular, salvo se as informações forem necessárias para eventual
    exercício de direitos em processo judicial ou administrativo, ou para cumprir obrigação legal.</p>
  <p class="s1" style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">
    Ressaltamos que conferem aos administradores dos licenciados a prerrogativa de auditar e editar dados alimentados na
    aplicação Axyma Forms, sendo impossibilitado à Contratante promover essas atividades.</p>
  <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">Em
    situações excepcionais e mediante prévia justificativa e solicitação, a Axyma poderá auxiliar na operação de ações
    de edição de bases de dados, excetuadas as hipóteses em que se constate que as edições dos registros tenham
    finalidades ilícitas.</p>
  <p class="s2" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">COOKIES</p>
  <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">
    Quando você visita nosso site , são inseridos cookies no seu navegador para aprimorar a sua experiência em nossas
    plataformas e páginas da internet. Com isso, só podemos identificar informações indiretas, como: o terminal de
    internet, a duração da sua visita e as páginas visitadas.</p>
  <p class="s1" style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: left;">Esses
    <i>cookies </i>registram a sua visita ao nosso site, as páginas que você acessou e as ações que implementou.
    Utilizaremos esses dados para tornar potencializar nossa publicidade e Marketing.</p>
  <p style="text-indent: 0pt;text-align: left;"><br /></p>
  <h3 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">DIREITOS GARANTIDOS AO TITULAR DOS DADOS PESSOAIS
  </h3>
  <p class="s1" style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">De acordo com o Capítulo
    III da Lei nº 13.709, de 14 de agosto de 2018 que diz sobre os Direitos do Titular:</p>
  <ul id="l6">
    <li data-list-text="–">
      <p class="s1" style="padding-top: 10pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Art. 17. Toda pessoa
        natural tem assegurada a titularidade de seus Dados Pessoais e garantidos os direitos fundamentais de liberdade,
        de intimidade e de privacidade, nos termos desta Lei.</p>
    </li>
    <li data-list-text="–">
      <p class="s1" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Art. 18. O titular dos Dados Pessoais
        tem direito a obter do controlador, em relação aos Dados do titular por ele tratados, a qualquer momento e
        mediante requisição:</p>
      <ol id="l7">
        <li data-list-text="I">
          <p class="s1"
            style="padding-top: 3pt;padding-left: 45pt;text-indent: -5pt;line-height: 10pt;text-align: left;">–
            confirmação da existência de tratamento;</p>
        </li>
        <li data-list-text="II">
          <p class="s1" style="padding-left: 48pt;text-indent: -7pt;line-height: 10pt;text-align: left;">– acesso aos
            Dados;</p>
        </li>
        <li data-list-text="III">
          <p class="s1" style="padding-left: 50pt;text-indent: -10pt;text-align: left;">– correção de Dados incompletos,
            inexatos ou desatualizados;</p>
        </li>
        <li data-list-text="IV">
          <p class="s1" style="padding-left: 40pt;text-indent: 0pt;text-align: left;">– anonimização, bloqueio ou
            eliminação de Dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</p>
        </li>
        <li data-list-text="V">
          <p class="s1" style="padding-left: 40pt;text-indent: 0pt;text-align: left;">– portabilidade dos Dados a outro
            fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade
            nacional, observados os segredos comercial e industrial;</p>
        </li>
        <li data-list-text="VI">
          <p class="s1" style="padding-left: 40pt;text-indent: 0pt;text-align: left;">– eliminação dos Dados Pessoais
            tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 desta Lei;</p>
        </li>
        <li data-list-text="VII">
          <p class="s1" style="padding-left: 40pt;text-indent: 0pt;text-align: left;">– informação das entidades
            públicas e privadas com as quais o controlador realizou uso compartilhado de Dados;</p>
        </li>
        <li data-list-text="VIII">
          <p class="s1" style="padding-left: 40pt;text-indent: 0pt;text-align: left;">– informação sobre a possibilidade
            de não fornecer consentimento e sobre as consequências da negativa;</p>
        </li>
        <li data-list-text="IX">
          <p class="s1" style="padding-left: 51pt;text-indent: -11pt;text-align: left;">– revogação do consentimento.
          </p>
        </li>
      </ol>
    </li>
  </ul>
  <p style="text-indent: 0pt;text-align: left;"><br /></p>
  <p class="s1" style="padding-left: 5pt;text-indent: 0pt;text-align: left;"><a href="mailto:contato@axyma.com"
      class="s6" target="_blank">Para solicitar o atendimento a qualquer destes direitos ou informações relacionadas a
      suas informações pessoais, entre em contato com nosso Chefe de Privacidade via e-mail: </a><a
      href="mailto:contato@axyma.com" class="a" target="_blank">contato@axyma.com</a>.br</p>
  <p style="text-indent: 0pt;text-align: left;"><br /></p>
  <h3 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">MUDANÇAS NA POLÍTICA DE PRIVACIDADE</h3>
  <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Essa Política de Privacidade pode
    passar por revisões e atualizações. Desta forma, recomendamos que você visite periodicamente esta página para que
    tenha conhecimento sobre as modificações. Caso sejam feitas alterações relevantes que necessitem de um novo
    consentimento seu, iremos publicar essa atualização e solicitar um novo consentimento para você.</p>
  <h3 style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">CONTATO</h3>
  <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a
      href="mailto:contato@axyma.com.br" class="s3" target="_blank">Qualquer dúvida ou solicitação em relação aos termos
      desse Aviso pode ser esclarecida, entrando em contato com nosso Chefe de Privacidade pelo e-mail:
      contato@axyma.com.br</a></p>
</body>

</html>`